* {
    margin: 0;
    padding: 0;
    font-family: "Plus Jakarta Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}
