.product-section-main {
    width: 100%;
    height: 100%;
    padding: 60px 0;

    .product-container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;
        box-sizing: border-box;

        .product-title-main {
            .product-title-main-alignment {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                h1 {
                    margin: 0 0 18px 0;
                    background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                    color: transparent;
                    -webkit-background-clip: text;
                    background-clip: text;
                    font-size: 18px;
                    font-weight: 500;
                    font-family: "Plus Jakarta Sans", sans-serif;
                }

                h2 {
                    font-size: 40px;
                    line-height: normal;
                    font-weight: 600;
                    font-family: "Plus Jakarta Sans", sans-serif;
                    color: #3073c6;
                    margin: 0 0 15px 0;
                    text-align: center;

                    @media (max-width: 1024px) {
                        font-size: 30px;
                    }
                }

                p {
                    font-family: "Plus Jakarta Sans", sans-serif;
                    color: #000;
                    font-size: 18px;
                    max-width: 800px;
                    text-align: center;
                    letter-spacing: 0.3px;
                    line-height: 1.4;

                    @media (max-width: 1024px) {
                        font-size: 16px;
                    }
                }

                .product-buttons-changer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    margin: 20px 0 0 0;
                    flex-wrap: nowrap;

                    @media (max-width: 1024px) {
                        gap: 10px;
                        flex-wrap: wrap;
                    }

                    button {
                        background-color: #3073c6;
                        border-radius: 50px;
                        border: none;
                        color: #fff;
                        padding: 12px 24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        transition: all 0.5s ease-in-out;
                        font-size: 15px;
                        font-family: "Plus Jakarta Sans", sans-serif;
                        font-weight: 700;

                        @media (max-width: 1024px) {
                            font-size: 12px;
                        }

                        &:focus {
                            outline: none;
                        }

                        &:hover {
                            transition: all 0.5s ease-in-out;
                            background-color: rgb(43 50 69/1);
                        }
                    }

                    .activebutton {
                        transition: all 0.5s ease-in-out;
                        background-color: #1f2532;
                        color: #fff;

                        &:hover {
                            transition: all 0.5s ease-in-out;
                            background-color: #1f2532;
                            color: #fff;
                        }
                    }
                }
            }

            #up-and-down-boxes-changer-mobile {
                display: none;

                @media (max-width: 1024px) {
                    display: grid;
                }
            }

            #up-and-down-boxes-changer-web {
                @media (max-width: 1024px) {
                    display: none;
                }
            }

            #up-and-down-boxes-changer-mobile-metals {
                display: none;

                @media (max-width: 1024px) {
                    display: grid;
                }
            }

            #up-and-down-boxes-changer-web-metals {
                @media (max-width: 1024px) {
                    display: none;
                }
            }

            #up-and-down-boxes-changer-mobile-shares {
                display: none;

                @media (max-width: 1024px) {
                    display: grid;
                }
            }

            #up-and-down-boxes-changer-web-shares {
                @media (max-width: 1024px) {
                    display: none;
                }
            }

            #up-and-down-boxes-changer-mobile-indices {
                display: none;

                @media (max-width: 1024px) {
                    display: grid;
                }
            }

            #up-and-down-boxes-changer-web-indices {
                @media (max-width: 1024px) {
                    display: none;
                }
            }

            #up-and-down-boxes-changer-mobile-commodities {
                display: none;

                @media (max-width: 1024px) {
                    display: grid;
                }
            }

            #up-and-down-boxes-changer-web-commodities {
                @media (max-width: 1024px) {
                    display: none;
                }
            }

            #up-and-down-boxes-changer-mobile-digital {
                display: none;

                @media (max-width: 1024px) {
                    display: grid;
                }
            }

            #up-and-down-boxes-changer-web-digital {
                @media (max-width: 1024px) {
                    display: none;
                }
            }

            .product-content {
                margin: 40px 0 0 0;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                align-items: center;

                .productimags {
                    text-align: center;
                    img {
                        width: 100%;

                        @media (max-width: 576px) {
                            width: 100%;
                            max-height: none;
                        }
                    }
                }

                @media (max-width: 1024px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                h1 {
                    font-size: 56px;
                    font-weight: 700;
                    line-height: normal;
                    color: #3073c6;
                    margin: 0 0 20px 0;

                    @media (max-width: 1024px) {
                        font-size: 32px;
                    }

                    span {
                        background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);

                        color: transparent;
                        -webkit-background-clip: text;
                        background-clip: text;
                    }
                }

                p {
                    text-align: left;
                    font-size: 18px;
                    font-weight: 400;
                    max-width: 600px;
                    margin: 0 0 40px 0;
                    letter-spacing: 0.3px;
                    line-height: 1.3;
                    color: #000;

                    @media (max-width: 1024px) {
                        font-size: 16px;
                    }
                }

                .up-and-down-boxes-changer {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    align-items: center;
                    gap: 10px;

                    @media (max-width: 800px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    .up-and-down-box-changer {
                        border-radius: 15px;
                        background-color: #3073c6;
                        padding: 19px;

                        h3 {
                            background-size: 200% auto !important;
                            color: #fff;
                            -webkit-background-clip: text !important;
                            background-clip: text;
                            font-size: 21px;
                            font-weight: 600;
                            line-height: normal;
                            text-align: left;
                            margin: 0 0 15px 0;
                        }

                        span {
                            color: #fff;
                            font-size: 15px;
                            font-weight: 600;
                            min-width: 291px;
                            display: block;

                            @media (max-width: 1024px) {
                                min-width: auto;
                            }
                        }
                    }
                }

                .up-and-down-boxes-changer-sc {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    align-items: center;
                    gap: 10px;

                    @media (max-width: 1024px) {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @media (max-width: 576px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    .up-and-down-box-changer {
                        border-radius: 15px;
                        background-color: #3073c6;
                        padding: 19px;

                        h3 {
                            background-size: 200% auto !important;
                            color: #fff;
                            -webkit-background-clip: text !important;
                            background-clip: text;
                            font-size: 21px;
                            font-weight: 600;
                            line-height: normal;
                            text-align: left;
                            margin: 0 0 15px 0;
                        }

                        span {
                            color: #fff;
                            font-size: 15px;
                            font-weight: 600;
                            max-width: 174px;
                            display: block;
                        }
                    }
                }

                .up-and-down-boxes-changer-th {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    align-items: center;
                    gap: 10px;

                    @media (max-width: 1024px) {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @media (max-width: 576px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    .up-and-down-box-changer {
                        border-radius: 15px;
                        background-color: #3073c6;
                        padding: 19px;
                        height: -webkit-fill-available;

                        h3 {
                            min-width: 180px;

                            background-size: 200% auto !important;
                            color: #fff;
                            -webkit-background-clip: text !important;
                            background-clip: text;
                            font-size: 21px;
                            font-weight: 600;
                            line-height: normal;
                            text-align: left;
                            margin: 0 0 15px 0;
                        }

                        span {
                            color: #fff;
                            font-size: 15px;
                            font-weight: 600;
                            max-width: 174px;
                            display: block;
                        }
                    }
                }

                .up-and-down-boxes-changer-frth {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    align-items: center;
                    gap: 10px;

                    @media (max-width: 1024px) {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @media (max-width: 576px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    .up-and-down-box-changer {
                        border-radius: 15px;
                        background-color: #3073c6;
                        padding: 19px;
                        height: -webkit-fill-available;

                        h3 {
                            min-width: 180px;

                            background-size: 200% auto !important;
                            color: #fff;
                            -webkit-background-clip: text !important;
                            background-clip: text;
                            font-size: 21px;
                            font-weight: 600;
                            line-height: normal;
                            text-align: left;
                            margin: 0 0 15px 0;
                        }

                        span {
                            color: #fff;
                            font-size: 15px;
                            font-weight: 600;
                            max-width: 165px;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
