.whyselectus-section-main {
    width: 100%;
    height: 100%;
    margin: 80px 0;

    .whyselectus-section-container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .whyselectus-section-title-main {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h1 {
                text-align: center;
                background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                color: transparent;
                -webkit-background-clip: text;
                background-clip: text;
                font-size: 18px;
                font-weight: 500;
                line-height: normal;
                margin: 0 0 15px 0;

                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }

            h2 {
                font-size: 40px;
                line-height: normal;
                font-weight: 600;
                color: #3073c6;
                margin: 0 0 15px 0;
                max-width: 829px;
                text-align: center;

                @media (max-width: 1024px) {
                    font-size: 30px;
                }

                @media (max-width: 576px) {
                    font-size: 22px;
                    margin-bottom: 10px;
                }
            }

            p {
                font-size: 18px;
                font-weight: 400;
                color: #000000;
                text-align: center;
                max-width: 1105px;
                margin-bottom: 50px;

                @media (max-width: 1024px) {
                    font-size: 16px;
                }

                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }
        }

        .whyselectus-cards-grid-main {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: center;
            gap: 20px;

            @media (max-width: 1024px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
            }
            .whyselectus-cards-grid-items1 {
                padding: 32px;
                background-image: linear-gradient(180deg, #fff, #ddebff);
                border-radius: 24px;
                display: flex;
                justify-content: start;
                align-items: start;
                gap: 15px;
                flex-direction: column;
                position: relative;
                overflow: hidden;
                height: -webkit-fill-available;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                @media (max-width: 576px) {
                    padding: 20px;
                    height: auto;
                }

                .whyselectus-cards-icons {
                    display: flex;
                    justify-content: start;
                    align-items: center;

                    img {
                        width: 100%;
                    }
                }

                h3 {
                    font-size: 24px;
                    color: #3073c6;
                    font-weight: 600;
                    line-height: normal;

                    @media (max-width: 1024px) {
                        font-size: 22px;
                    }

                    @media (max-width: 576px) {
                        font-size: 18px;
                    }
                }

                p {
                    color: #000000;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.3;

                    @media (max-width: 576px) {
                        font-size: 14px;
                    }
                }

                .whyselectus-cards-bottom-line-sc {
                    background: rgb(33, 203, 128) !important;
                }

                .whyselectus-cards-bottom-line-trd {
                    background: rgb(245, 155, 71) !important;
                }

                .whyselectus-cards-bottom-line-frth {
                    background: rgb(56 128 189) !important;
                }

                .whyselectus-cards-bottom-line {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background: rgb(70, 123, 255);
                }
            }
        }
    }
}
