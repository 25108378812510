.partnerib-mian {
    padding: 0 0 50px 0;

    .container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .partnerib-back-img {
            background-image: url(../../assets/images/partnerib.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            padding: 50px 0;

            .section-st {
                align-items: center;
                display: flex;
                flex-direction: column;
                gap: 20px;
                justify-content: center;

                h1 {
                    color: #3073c6;
                    font-size: 40px;
                    max-width: 845px;
                    line-height: 1.4;
                    text-align: center;

                    @media (max-width: 1024px) {
                        font-size: 30px;
                    }
                }

                p {
                    color: #000;
                    font-size: 16px;
                    text-align: center;
                    max-width: 833px;
                    line-height: 1.4;

                    @media (max-width: 576px) {
                        font-size: 14px;
                    }
                }

                .beomeib {
                    button {
                        background-image: linear-gradient(88.9deg, #3073c6, #64e584 50%, #64e584 75%, #3073c6 120%);
                        padding: 10px;
                        border: none;
                        border-radius: 50px;
                        width: 100%;
                        font-size: 15px;
                        color: #ffffff;
                        font-weight: 600;
                        background-size: 200% auto !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 15px;
                        transition: 0.4s ease-in-out;
                        cursor: pointer;

                        &:hover {
                            transition: 0.4s ease-in-out;
                            background: linear-gradient(
                                88.9deg,
                                #467bff,
                                #75d9d9 50%,
                                #75d9d9 75%,
                                #467bff 120%
                            ) !important;
                            background-size: 200% auto !important;
                            background-position: 100% !important;
                        }
                    }
                }
            }
        }
    }
}

.partnering-perks {
    padding: 50px 0;

    .conatiner {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .common-section {
            h2 {
                color: #3073c6;
                font-size: 28px;
            }

            .rewards-boxes {
                margin-top: 70px;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 15px;
                text-align: center;
                justify-content: center;
                align-items: center;

                @media (max-width: 1024px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @media (max-width: 576px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                .icon-text {
                    border: 2px solid #cecece;
                    border-radius: 25px;
                    padding: 20px;
                    height: 100%;
                    box-sizing: border-box;

                    .details-rewards {
                        p {
                            margin-top: 10px;
                            color: #000;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
