.get-in-touch {
    padding: 100px 0 80px 0;

    .container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .title-get {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;

            h1 {
                font-weight: 700;
                line-height: normal;
                font-size: 40px;
                text-align: center;
                color: #3073c6;
            }

            p {
                font-size: 18px;
                font-weight: 500;
                line-height: 1.2;
                color: #000000;
                max-width: 584px;
                text-align: center;
            }
        }

        .contact-social-boxes-main {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 24px;
            width: 100%;
            height: 100%;
            padding: 0;
            box-sizing: border-box;
            align-items: center;

            @media (max-width: 1024px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
                padding: 0 40px;
                gap: 10px;
            }

            .whychoose-main {
                position: relative;
                margin: 70px 0 0 0;

                @media (max-width: 1024px) {
                    margin: 60px 0 0 0;
                }

                @media (max-width: 576px) {
                    margin: 70px 0 0 0;
                }

                &.active {
                    .whychoose {
                        background-image: linear-gradient(180deg, #fff, #c4ccdd);
                        transition: 0.4s ease-in-out;

                        p {
                            font-weight: 500;
                            color: #060b22;
                        }

                        h3 {
                            color: #060b22;
                        }
                    }
                }

                .whychoose {
                    height: 153px;
                    position: relative;
                    overflow: hidden;
                    background-image: linear-gradient(142.26deg, #3073c6 1.22%, #7fa5d3 105.61%);
                    border-radius: 24px;
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    flex-direction: column;
                    transition: 0.4s ease-in-out;

                    @media (max-width: 576px) {
                        height: 100px;
                    }

                    &:hover {
                        background-image: linear-gradient(180deg, #fff, #c4ccdd);
                        transition: 0.4s ease-in-out;

                        p {
                            font-weight: 500;
                            color: #060b22;
                        }

                        h3 {
                            color: #060b22;
                        }
                    }

                    &:before {
                        background: linear-gradient(
                            99.93deg,
                            rgba(136, 149, 172, 0.4),
                            rgba(32, 40, 59, 0.4) 10.42%,
                            rgba(74, 92, 135, 0.4) 32.81%,
                            rgba(32, 40, 59, 0.4)
                        );
                        content: "";
                        position: absolute;
                        inset: 0;
                        border-radius: inherit;
                        -webkit-mask:
                            linear-gradient(#fff 0 0) content-box,
                            linear-gradient(#fff 0 0);
                        -webkit-mask-composite: xor;
                        mask-composite: exclude;
                        padding: 2px;
                    }

                    h3 {
                        padding: 0 0 20px 0;
                        text-align: center;
                        z-index: 1;
                        font-weight: 700;
                        color: #ffffff;
                        font-size: 22px;

                        @media (max-width: 1024px) {
                            font-size: 16px;
                        }

                        @media (max-width: 576px) {
                            font-size: 10px;
                        }
                    }

                    p {
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 1.2;
                        color: #ffffff;
                        margin: 0 0 10px 0;
                    }

                    .stripedCard {
                        position: absolute;
                        top: 50%;

                        img {
                            width: 100%;
                        }
                    }
                }

                .Whyforexicon {
                    position: absolute;
                    top: -70px;
                    left: 50%;
                    transform: translateX(-50%);

                    img {
                        width: 100%;
                    }

                    @media (max-width: 1024px) {
                        top: -65px;
                    }

                    @media (max-width: 576px) {
                        top: -82px;
                    }
                }
            }
        }
    }
}
