html {
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
}

a {
  text-decoration-line: none;
}

body {
  background-color: #ffffff;

  line-height: 1.2;
  font-family: "Plus Jakarta Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(88.9deg, #3073c6, #64e584 50%, #64e584 75%, #3073c6 120%);
  border-radius: 0.208vw;
}

::-moz-selection {
  background-color: rgb(175 187 207);
  --tw-text-opacity: 1;
  color: rgb(255 255 255);
}

::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(175 187 207);
  --tw-text-opacity: 1;
  color: rgb(255 255 255);
}

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  /* user-drag: none; */
  -webkit-touch-callout: none;
}
