.product-section-main-forex {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    background-image: url(../../assets/images/colored.png);
    background-position: end end;
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotateY(180deg);

    .product-container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;
        box-sizing: border-box;
        transform: rotateY(180deg);

        .product-title-main {
            .product-title-main-alignment {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .product-buttons-changer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    margin: 20px 0 0 0;
                    flex-wrap: nowrap;

                    @media (max-width: 1024px) {
                        gap: 10px;
                        flex-wrap: wrap;
                    }

                    button {
                        background-color: #3073c6;
                        border-radius: 50px;
                        border: none;
                        color: #fff;
                        padding: 12px 24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                        cursor: pointer;
                        transition: all 0.5s ease-in-out;
                        font-size: 15px;
                        font-family: "Plus Jakarta Sans", sans-serif;
                        font-weight: 700;

                        @media (max-width: 1024px) {
                            font-size: 12px;
                        }

                        &:focus {
                            outline: none;
                        }

                        &:hover {
                            transition: all 0.5s ease-in-out;
                            background-color: rgb(43 50 69/1);
                        }
                    }

                    .activebutton {
                        transition: all 0.5s ease-in-out;
                        background-color: #1f2532;
                        color: #fff;

                        &:hover {
                            transition: all 0.5s ease-in-out;
                            background-color: #1f2532;
                            color: #fff;
                        }
                    }
                }
            }

            #up-and-down-boxes-changer-mobile {
                display: none;

                @media (max-width: 1024px) {
                    display: grid;
                }
            }

            #up-and-down-boxes-changer-web {
                @media (max-width: 1024px) {
                    display: none;
                }
            }

            #up-and-down-boxes-changer-mobile-metals {
                display: none;

                @media (max-width: 1024px) {
                    display: grid;
                }
            }

            #up-and-down-boxes-changer-web-metals {
                @media (max-width: 1024px) {
                    display: none;
                }
            }

            #up-and-down-boxes-changer-mobile-shares {
                display: none;

                @media (max-width: 1024px) {
                    display: grid;
                }
            }

            #up-and-down-boxes-changer-web-shares {
                @media (max-width: 1024px) {
                    display: none;
                }
            }

            #up-and-down-boxes-changer-mobile-indices {
                display: none;

                @media (max-width: 1024px) {
                    display: grid;
                }
            }

            #up-and-down-boxes-changer-web-indices {
                @media (max-width: 1024px) {
                    display: none;
                }
            }

            #up-and-down-boxes-changer-mobile-commodities {
                display: none;

                @media (max-width: 1024px) {
                    display: grid;
                }
            }

            #up-and-down-boxes-changer-web-commodities {
                @media (max-width: 1024px) {
                    display: none;
                }
            }

            #up-and-down-boxes-changer-mobile-digital {
                display: none;

                @media (max-width: 1024px) {
                    display: grid;
                }
            }

            #up-and-down-boxes-changer-web-digital {
                @media (max-width: 1024px) {
                    display: none;
                }
            }

            .product-content {
                margin: 30px 0 0 0;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                align-items: center;

                .productimags {
                    text-align: center;
                    img {
                        max-height: 500px;

                        @media (max-width: 576px) {
                            width: 100%;
                            max-height: none;
                        }
                    }
                }

                @media (max-width: 1024px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                h1 {
                    font-size: 56px;
                    font-weight: 700;
                    line-height: normal;
                    color: #3073c6;
                    margin: 0 0 20px 0;

                    @media (max-width: 1024px) {
                        font-size: 32px;
                    }

                    span {
                        background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                        color: transparent;
                        -webkit-background-clip: text;
                        background-clip: text;
                    }
                }

                p {
                    text-align: left;
                    font-size: 18px;
                    font-weight: 400;
                    max-width: 600px;
                    margin: 0 0 40px 0;
                    letter-spacing: 0.3px;
                    line-height: 1.3;

                    @media (max-width: 1024px) {
                        font-size: 16px;
                    }
                }

                .up-and-down-boxes-changer {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    align-items: center;
                    gap: 10px;

                    .up-and-down-box-changer {
                        border-radius: 15px;
                        border: 1px solid rgb(43 50 69/1);
                        background-color: #3073c6;
                        padding: 19px;

                        h3 {
                            background-size: 200% auto !important;
                            color: #fff;
                            -webkit-background-clip: text !important;
                            background-clip: text;
                            font-size: 21px;
                            font-weight: 600;
                            line-height: normal;
                            text-align: left;
                            margin: 0 0 15px 0;
                        }

                        span {
                            color: #fff;
                            font-size: 15px;
                            font-weight: 600;
                            min-width: 291px;
                            display: block;

                            @media (max-width: 1024px) {
                                min-width: auto;
                            }
                        }
                    }
                }

                .start-open-buttons {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 10px;
                    margin-top: 30px;

                    @media (max-width: 1024px) {
                        grid-template-columns: repeat(1, 1fr);
                    }
                    .start {
                        button {
                            background-image: linear-gradient(88.9deg, #3073c6, #64e584 50%, #64e584 75%, #3073c6 120%);
                            padding: 15px;
                            border: none;
                            border-radius: 50px;
                            width: 100%;
                            font-size: 20px;
                            color: #ffffff;
                            font-weight: 600;
                            background-size: 200% auto !important;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 15px;
                            transition: 0.4s ease-in-out;
                            cursor: pointer;

                            @media (max-width: 1024px) {
                                padding: 10px;
                            }

                            &:hover {
                                transition: 0.4s ease-in-out;
                                background: linear-gradient(
                                    88.9deg,
                                    #467bff,
                                    #75d9d9 50%,
                                    #75d9d9 75%,
                                    #467bff 120%
                                ) !important;
                                background-size: 200% auto !important;
                                background-position: 100% !important;
                            }

                            svg {
                                margin: 3px 0 0 0;
                                background-color: transparent;
                                path {
                                    stroke: #fff;
                                }
                            }
                        }
                    }

                    .open {
                        border: none;
                        border-radius: 50px;
                        background-color: transparent;
                        padding: 1px;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            background-color: #fff;
                            transition: all 0.2s ease-in-out;
                        }
                        button {
                            background-color: transparent;
                            padding: 15px;
                            border-radius: 50px;
                            width: 100%;
                            border: none;
                            position: relative;
                            background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                            -webkit-background-clip: text;
                            background-clip: text;
                            color: transparent;
                            font-size: 20px;
                            font-weight: 600;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 5px;
                            cursor: pointer;

                            @media (max-width: 576px) {
                                padding: 12px;
                                font-size: 16px;
                            }

                            &:before {
                                background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                                content: "";
                                position: absolute;
                                inset: 0;
                                border-radius: inherit;
                                -webkit-mask:
                                    linear-gradient(#fff 0 0) content-box,
                                    linear-gradient(#fff 0 0);
                                -webkit-mask-composite: xor;
                                mask-composite: exclude;
                                padding: 2px;
                            }

                            svg {
                                background-color: transparent;
                            }
                        }
                    }
                }

                .up-and-down-boxes-changer-sc {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    align-items: center;
                    gap: 10px;

                    @media (max-width: 576px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    .up-and-down-box-changer {
                        border-radius: 15px;
                        border: 1px solid rgb(43 50 69/1);
                        background-color: #3073c6;
                        padding: 19px;

                        h3 {
                            background-size: 200% auto !important;
                            color: #ffffff;
                            -webkit-background-clip: text !important;
                            background-clip: text;
                            font-size: 21px;
                            font-weight: 600;
                            line-height: normal;
                            text-align: left;
                            margin: 0 0 15px 0;
                        }

                        span {
                            color: #ffffff;
                            font-size: 15px;
                            font-weight: 600;
                            max-width: 174px;
                            display: block;
                        }
                    }
                }

                .up-and-down-boxes-changer-th {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    align-items: center;
                    gap: 10px;

                    @media (max-width: 576px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    .up-and-down-box-changer {
                        border-radius: 15px;
                        border: 1px solid rgb(43 50 69/1);
                        background-color: #3073c6;
                        padding: 19px;
                        height: -webkit-fill-available;

                        h3 {
                            min-width: 180px;
                            background-size: 200% auto !important;
                            color: #ffffff;
                            -webkit-background-clip: text !important;
                            background-clip: text;
                            font-size: 21px;
                            font-weight: 600;
                            line-height: normal;
                            text-align: left;
                            margin: 0 0 15px 0;
                        }

                        span {
                            color: #ffffff;
                            font-size: 15px;
                            font-weight: 600;
                            max-width: 174px;
                            display: block;
                        }
                    }
                }

                .up-and-down-boxes-changer-frth {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    align-items: center;
                    gap: 10px;

                    @media (max-width: 576px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    .up-and-down-box-changer {
                        border-radius: 15px;
                        border: 2px solid rgb(43 50 69/1);
                        background-color: #3073c6;
                        padding: 19px;
                        height: -webkit-fill-available;

                        h3 {
                            min-width: 180px;
                            background-size: 200% auto !important;
                            color: #ffffff;
                            -webkit-background-clip: text !important;
                            background-clip: text;
                            font-size: 21px;
                            font-weight: 600;
                            line-height: normal;
                            text-align: left;
                            margin: 0 0 15px 0;
                        }

                        span {
                            color: #ffffff;
                            font-size: 15px;
                            font-weight: 600;
                            max-width: 165px;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.section-mt {
    margin: 100px 0;

    .bgliner-color {
        padding: 50px 0;
        background-image: radial-gradient(53.28% 46.13% at 53.28% 57.78%, #3073c6 0, #5e8dc6 100%);

        .container {
            max-width: 1372px;
            margin: 0 auto;
            padding: 0 20px;

            .what-forex {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-items: center;
                gap: 130px;

                @media (max-width: 1024px) {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 20px;
                }

                .forex-text {
                    h3 {
                        color: #ffffff;
                        font-size: 40px;
                        font-weight: 700;
                    }
                    span {
                        background: linear-gradient(88.9deg, #ffffff 33.43%, #64e584 103.55%);
                        color: transparent;
                        background-clip: text;
                    }

                    .forex-peragraph {
                        margin-top: 20px;
                        p {
                            font-size: 18px;
                            color: #ffffff;
                            margin-top: 20px;
                            line-height: 1.3;
                            font-weight: 500;
                        }
                    }
                }

                .what-is-img {
                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.trade-forex {
    margin: 100px 0;

    .container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .trade-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            justify-content: center;
            gap: 120px;

            @media (max-width: 1024px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 20px;
            }

            .start-trading {
                h3 {
                    color: #3073c6;
                    font-size: 40px;
                }

                span {
                    background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                    color: transparent;
                    background-clip: text;
                    font-size: 40px;
                    font-weight: 700;
                }
                p {
                    font-size: 18px;
                    color: #000000;
                    margin-top: 16px;
                    line-height: 1.3;
                    max-width: 460px;

                    @media (max-width: 1024px) {
                        max-width: none;
                    }
                }
            }

            .trade-with-mb {
                width: 60%;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }

                @media (max-width: 1024px) {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 292px;
                }
            }
        }
    }
}

.card-seaction {
    margin: 100px 0;

    .conatiner {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .cards-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;
            align-items: center;

            @media (max-width: 1024px) {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
            }

            .whyselectus-cards-grid-items1 {
                padding: 32px;
                background-image: linear-gradient(142.26deg, #5d91d1 1.22%, #477fc4 105.61%);
                border-radius: 24px;
                display: flex;
                justify-content: start;
                align-items: start;
                gap: 10px;
                flex-direction: column;
                position: relative;
                overflow: hidden;
                height: -webkit-fill-available;

                @media (max-width: 576px) {
                    padding: 20px;
                    height: auto;
                }

                span {
                    color: #ffffff;
                    font-size: 24px;
                    font-weight: 700;
                }

                p {
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.3;
                    padding-top: 10px;

                    @media (max-width: 1024px) {
                        max-width: 260px;
                    }

                    @media (max-width: 576px) {
                        font-size: 14px;
                    }
                }

                .whyselectus-cards-bottom-line-sc {
                    width: 20%;
                    height: 3px;
                    border-radius: 5px;
                    background: #f59b47;
                }

                .whyselectus-cards-bottom-line-trd {
                    width: 20%;
                    height: 3px;
                    border-radius: 5px;
                    background: #e5294b;
                }

                .whyselectus-cards-bottom-line {
                    width: 20%;
                    height: 3px;
                    border-radius: 5px;
                    background: rgb(33, 203, 128);
                }
            }
        }
    }
}
