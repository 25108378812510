.herobannermain {
    background-image: url(../../../assets/images/herobanner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
        background-color: rgba(0, 0, 0, 0.541);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        content: "";
    }

    @media (max-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0;
    }

    .herobannercontent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        gap: 30px;
        max-width: 615px;
        background-color: transparent;

        @media (max-width: 1024px) {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            box-sizing: border-box;
            padding: 0;
        }

        @media (max-width: 576px) {
            padding: 20px;
        }

        .text {
            background-color: transparent;
            h2 {
                font-size: 55px;
                color: #ffffff;

                @media (max-width: 1024px) {
                    font-size: 50px;
                    max-width: 302px;
                }

                @media (max-width: 576px) {
                    font-size: 40px;
                }

                span {
                    background: linear-gradient(88.9deg, #ffffff 33.43%, #64e584 103.55%);
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                }
            }
        }

        p {
            font-size: 16px;
            color: #ffffff;
            font-weight: 600;

            @media (max-width: 1024px) {
                text-align: center;
            }
        }

        .button {
            @media (max-width: 1024px) {
                width: 100%;
            }
            button {
                background-image: linear-gradient(88.9deg, #ffffff, #64e584 50%, #64e584 75%, #ffffff 120%);
                padding: 15px;
                border: none;
                border-radius: 50px;
                width: 100%;
                font-size: 22px;
                color: #3073c6;
                font-weight: 600;
                background-size: 200% auto !important;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;
                transition: 0.4s ease-in-out;
                cursor: pointer;

                @media (max-width: 1024px) {
                    font-size: 18px;
                }

                @media (max-width: 576px) {
                    font-size: 16px;
                    padding: 12px;
                }

                &:hover {
                    transition: 0.4s ease-in-out;
                    background-image: linear-gradient(88.9deg, #ffffff, #64e584 50%, #64e584 75%, #ffffff 120%);
                    background-size: 200% auto !important;
                    background-position: 100% !important;
                }

                svg {
                    margin: 3px 0 0 0;
                    background-color: transparent;
                    path {
                        stroke: #fff;
                    }
                }
            }
        }

        .demo-ib-button {
            position: relative;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            background-color: transparent;

            @media (max-width: 1024px) {
                width: 100%;
            }

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .demoaccount {
                border: none;
                border-radius: 50px;
                background-color: transparent;
                padding: 1px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: #fff;
                    transition: all 0.2s ease-in-out;

                    button {
                        background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                        background-color: transparent;
                        -webkit-background-clip: text;
                        background-clip: text;
                        color: transparent;
                    }
                }
                button {
                    background-color: transparent;
                    padding: 15px;
                    border-radius: 50px;
                    width: 100%;
                    border: none;
                    position: relative;
                    background: linear-gradient(88.9deg, #ffffff 33.43%, #64e584 103.55%);
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                    font-size: 20px;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    cursor: pointer;

                    @media (max-width: 576px) {
                        padding: 12px;
                        font-size: 16px;
                    }

                    &:before {
                        background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                        content: "";
                        position: absolute;
                        inset: 0;
                        border-radius: inherit;
                        -webkit-mask:
                            linear-gradient(#fff 0 0) content-box,
                            linear-gradient(#fff 0 0);
                        -webkit-mask-composite: xor;
                        mask-composite: exclude;
                        padding: 2px;
                    }

                    svg {
                        background-color: transparent;
                    }
                }
            }
        }

        .metatraders {
            position: relative;
            .tradeicon {
                background-color: transparent;
                img {
                    background-color: transparent;
                }
            }
        }
    }
}
