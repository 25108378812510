.restrictedpolicy-page-main-alignment {
    padding: 50px 0;

    @media (max-width: 576px) {
        padding: 20px 0;
    }

    .restrictedpolicy-page-main {
        max-width: 1140px;
        margin: 0 auto;
        padding: 0 20px;

        h1 {
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            color: #3073c6;
            margin: 50px 0 16px 0;

            @media (max-width: 576px) {
                font-size: 24px;
                margin: 0;
            }
        }

        .restrictedpolicy-page-content {
            margin: 10px 0 0 0;

            p {
                font-size: 15px;
                font-weight: 00;
                line-height: 1.5;
                margin: 10px 0 0;
                text-align: justify;
                color: #000;

                @media (max-width: 576px) {
                    font-size: 12px;
                }
            }

            ul {
                margin-left: 20px;
                margin-top: 10px;
                padding: 0 0 0 10px;
                list-style: disc;

                li {
                    color: #000;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 1.5;
                    margin: 10px 0 0;
                    text-align: justify;

                    @media (max-width: 576px) {
                        font-size: 12px;
                    }
                }
            }

            .restrictedpolicy-page-content-ul-main {
                display: flex;
                flex-wrap: wrap;

                li {
                    flex: 1 1 auto;
                    padding-right: 30px;
                    text-align: left;
                    width: 22%;
                }
            }
        }
    }
}
