.becom-an-partner-main {
    padding: 48px 0;

    .becom-an-partner-container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;
    }

    .becom-an-partner-content {
        h1 {
            color: #0d1721;
            font-size: 28px;
            font-weight: 700;
            margin: 0 0 30px 0;
        }

        .embark-pera-main {
            display: flex;
            justify-content: space-between;
            align-items: end;
            gap: 10px;

            @media (max-width: 1024px) {
                flex-direction: column;
                align-items: start;
            }

            p {
                font-size: 16px;
                font-weight: 500;
                color: #0d1721;
                line-height: normal;
                max-width: 1000px;
            }
            button {
                background-image: linear-gradient(88.9deg, #3073c6, #64e584 50%, #64e584 75%, #3073c6 120%);
                padding: 10px;
                border: none;
                border-radius: 50px;
                width: fit-content;
                font-size: 15px;
                color: #ffffff;
                font-weight: 600;
                background-size: 200% auto !important;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;
                transition: 0.4s ease-in-out;
                cursor: pointer;

                &:hover {
                    transition: 0.4s ease-in-out;
                    background: linear-gradient(88.9deg, #467bff, #75d9d9 50%, #75d9d9 75%, #467bff 120%) !important;
                    background-size: 200% auto !important;
                    background-position: 100% !important;
                }
            }
        }
    }

    .becom-an-partner-content-sc {
        display: flex;
        justify-content: space-between;
        max-width: 950px;
        margin: 30px auto 0 auto;

        @media (max-width: 1024px) {
            flex-direction: column;
            gap: 0;
        }

        .becom-an-partner-content-sc-box {
            padding: 0 10px;
            position: relative;

            .becom-an-partner-content-sc-box-alignment-main {
                max-width: 200px;
                width: 100%;

                @media (max-width: 1024px) {
                    align-items: center;
                    display: flex;
                    gap: 25px;
                    max-width: 100%;
                    width: 100%;
                }

                .becom-an-partner-content-sc-box-line {
                    background-color: #3073c6;
                    height: 1px;
                    left: 45%;
                    position: relative;
                    position: absolute;
                    top: 50px;
                    width: 90%;
                    display: block;

                    @media (max-width: 1024px) {
                        bottom: 0;
                        display: block;
                        left: 65px;
                        height: 50%;
                        top: inherit;
                        width: 1px;
                    }

                    &::before {
                        background-color: #3073c6;
                        content: "";
                        height: 10px;
                        position: absolute;
                        right: 0;
                        top: -4px;
                        transform: rotate(45deg);
                        width: 10px;

                        @media (max-width: 1024px) {
                            left: 50%;
                            top: -4px;
                            transform: translate(-52%, -50%) rotate(45deg);
                        }
                    }

                    &:after {
                        background-color: #3073c6;
                        content: "";
                        height: 10px;
                        left: 0;
                        position: absolute;
                        top: -4px;
                        transform: rotate(45deg);
                        width: 10px;

                        @media (max-width: 1024px) {
                            display: none;
                        }
                    }
                }

                h1 {
                    font-size: 70px;
                    font-weight: 300;
                    margin-bottom: 5px;
                    color: #d2e5f1;
                    line-height: normal;
                    margin: 0 0 5px 0;

                    @media (max-width: 1024px) {
                        font-size: 60px;
                        margin-right: 30px;
                        width: 30px;
                        line-height: 90px;
                    }
                }

                p {
                    color: #0d1721;
                    line-height: normal;
                    font-weight: 500;
                    font-size: 20px;
                    margin: 5px 0 0 0;
                }
            }

            .becom-an-partner-content-sc-box-alignment-main-sc {
                .becom-an-partner-content-sc-box-line {
                    background-color: #3073c6;
                    height: 1px;
                    left: 45%;
                    position: relative;
                    position: absolute;
                    top: 50px;
                    width: 90%;
                    display: block;

                    @media (max-width: 1024px) {
                        height: 100%;
                        left: 65px;
                        top: 0;
                        width: 1px;
                    }

                    &::before {
                        background-color: #3073c6;
                        content: "";
                        height: 10px;
                        position: absolute;
                        right: 0;
                        top: -4px;
                        transform: rotate(45deg);
                        width: 10px;

                        @media (max-width: 1024px) {
                            left: 50%;
                            top: 50%;
                            transform: translate(-52%, -50%) rotate(45deg);
                        }
                    }

                    &:after {
                        background-color: #3073c6;
                        content: "";
                        height: 10px;
                        left: 0;
                        position: absolute;
                        top: -4px;
                        transform: rotate(45deg);
                        width: 10px;

                        @media (max-width: 1024px) {
                            display: none;
                        }
                    }
                }

                h1 {
                    color: #86bcff !important;
                }
            }

            .becom-an-partner-content-sc-box-alignment-main-thr {
                .becom-an-partner-content-sc-box-line {
                    background-color: #3073c6;
                    height: 1px;
                    left: 45%;
                    position: relative;
                    position: absolute;
                    top: 50px;
                    width: 90%;
                    display: none;

                    @media (max-width: 1024px) {
                        height: 50%;
                        top: 0;
                        left: 65px;
                        width: 1px;
                        display: block;
                    }

                    &::before {
                        background-color: #3073c6;
                        content: "";
                        height: 10px;
                        position: absolute;
                        right: 0;
                        top: -4px;
                        transform: rotate(45deg);
                        width: 10px;

                        @media (max-width: 1024px) {
                            left: 50%;
                            bottom: -4px;
                            top: auto;
                            transform: translate(-52%, -50%) rotate(45deg);
                        }
                    }

                    &:after {
                        background-color: #3073c6;
                        content: "";
                        height: 10px;
                        left: 0;
                        position: absolute;
                        top: -4px;
                        transform: rotate(45deg);
                        width: 10px;

                        @media (max-width: 1024px) {
                            display: none;
                        }
                    }
                }
                h1 {
                    color: #3073c6 !important;
                }
            }
        }
    }
}
