.strip-bg-colored {
    background-image: linear-gradient(180deg, #a1c4f0, #3073c6);
    padding: 50px 0;

    .conatiner {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .mttradingplatform {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 50px;

            @media (max-width: 1024px) {
                display: block;
            }

            .platformstrader {
                .span-platfrom {
                    span {
                        font-size: 18px;
                        color: #ffffff;

                        @media (max-width: 576px) {
                            font-size: 16px;
                        }
                    }
                }

                .trading-text {
                    h2 {
                        color: #ffffff;
                        font-size: 55px;
                        span {
                            background: linear-gradient(88.9deg, #ffffff 33.43%, #64e584 103.55%);
                            -webkit-background-clip: text;
                            background-clip: text;
                            color: transparent;
                        }

                        @media (max-width: 1024px) {
                            font-size: 30px;
                        }
                    }
                }

                .traderline1 {
                    margin-top: 20px;
                    p {
                        font-size: 18px;
                        color: #ffffff;
                        letter-spacing: 0.3px;
                    }
                }
                .traderline2 {
                    margin-top: 20px;
                    p {
                        font-size: 18px;
                        color: #ffffff;
                        letter-spacing: 0.3px;
                    }
                }

                .trader-button {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 10px;
                    margin-top: 30px;

                    @media (max-width: 1024px) {
                        grid-template-columns: repeat(1, 1fr);
                    }
                    .start {
                        button {
                            background-image: linear-gradient(88.9deg, #ffffff, #64e584 50%, #64e584 75%, #ffffff 120%);
                            padding: 15px;
                            border: none;
                            border-radius: 50px;
                            width: 100%;
                            font-size: 20px;
                            color: #3073c6;
                            font-weight: 600;
                            background-size: 200% auto !important;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 15px;
                            transition: 0.4s ease-in-out;
                            cursor: pointer;

                            @media (max-width: 1024px) {
                                padding: 10px;
                            }

                            &:hover {
                                transition: 0.4s ease-in-out;
                                background: linear-gradient(
                                    88.9deg,
                                    #ffffff,
                                    #75d9d9 50%,
                                    #75d9d9 75%,
                                    #ffffff 120%
                                ) !important;
                                background-size: 200% auto !important;
                                background-position: 100% !important;
                            }

                            svg {
                                margin: 3px 0 0 0;
                                background-color: transparent;
                                path {
                                    stroke: #fff;
                                }
                            }
                        }
                    }

                    .open {
                        border: none;
                        border-radius: 50px;
                        background-color: transparent;
                        padding: 1px;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            background-color: #fff;
                            transition: all 0.2s ease-in-out;
                            background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                        }
                        button {
                            background-color: transparent;
                            padding: 15px;
                            border-radius: 50px;
                            width: 100%;
                            border: none;
                            position: relative;
                            background: linear-gradient(88.9deg, #ffffff 33.43%, #64e584 103.55%);
                            -webkit-background-clip: text;
                            background-clip: text;
                            color: transparent;
                            font-size: 20px;
                            font-weight: 600;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 5px;
                            cursor: pointer;

                            @media (max-width: 576px) {
                                padding: 12px;
                                font-size: 16px;
                            }

                            &:before {
                                background: linear-gradient(88.9deg, #ffffff 33.43%, #64e584 103.55%);
                                content: "";
                                position: absolute;
                                inset: 0;
                                border-radius: inherit;
                                -webkit-mask:
                                    linear-gradient(#fff 0 0) content-box,
                                    linear-gradient(#fff 0 0);
                                -webkit-mask-composite: xor;
                                mask-composite: exclude;
                                padding: 2px;
                            }

                            svg {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

            .bannermt5 {
                width: 100%;
                img {
                }

                @media (max-width: 1024px) {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.white-background {
    padding: 35px 0;
    margin: 80px 0;
    background-image: linear-gradient(180deg, #fff, #b1d1f9);

    .container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .downloadapp-grid {
            // display: grid;
            // grid-template-columns: repeat(2, 1fr);
            // gap: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 30px;

            @media (max-width: 1024px) {
                // grid-template-columns: repeat(1, 1fr);
                flex-direction: column;
            }

            .meta5app-detail {
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                gap: 15px;

                @media (max-width: 1024px) {
                    gap: 10px;
                }

                .meta5-svg {
                    width: 100%;
                    img {
                    }
                }

                h3 {
                    font-size: 40px;
                    font-weight: 700;
                    color: #161a2f;
                    span {
                        color: #3073c6;
                    }

                    @media (max-width: 576px) {
                        font-size: 25px;
                    }
                }

                p {
                    color: #292b31;
                    font-size: 16px;
                    font-weight: 600;
                }

                .apps-button {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    justify-content: start;
                    flex-wrap: wrap;

                    @media (max-width: 1024px) {
                        justify-content: center;
                        margin-top: 10px;
                    }

                    @media (max-width: 576px) {
                        gap: 10px;
                        flex-direction: column;
                    }

                    .appstore-buton-main {
                        @media (max-width: 576px) {
                            width: 100%;
                        }
                        button {
                            background-color: #000;
                            color: #3073c6;
                            padding: 12px 40px;
                            border: none;
                            border-radius: 50px;
                            cursor: pointer;

                            @media (max-width: 576px) {
                                padding: 12px 20px;
                                width: 100%;
                            }

                            &:hover {
                                background-color: #fff;

                                svg {
                                    path {
                                        fill: #000 !important;
                                    }
                                }

                                .appstore {
                                    p {
                                        color: #000 !important;
                                    }
                                    span {
                                        color: #000 !important;
                                    }
                                }
                            }

                            .apple-icon-flex {
                                display: flex;
                                justify-content: space-between;
                                gap: 10px;

                                @media (max-width: 576px) {
                                    justify-content: center;
                                    gap: 15px;
                                }

                                .apple-icon {
                                    img,
                                    svg {
                                        height: 40px;
                                        width: 40px;

                                        @media (max-width: 576px) {
                                            height: 25px;
                                            width: 25px;
                                        }
                                    }

                                    svg {
                                        path {
                                            fill: #fff;
                                        }
                                    }
                                }

                                .appstore {
                                    text-align: left;
                                    p {
                                        font-size: 12px;
                                        color: #ffffff;

                                        @media (max-width: 576px) {
                                            font-size: 10px;
                                        }
                                    }
                                    span {
                                        font-size: 20px;
                                        color: #ffffff;
                                        font-weight: 600;

                                        @media (max-width: 576px) {
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .downloadapp-img {
                // width: 100%;
                text-align: center;
                img {
                    height: 323px;

                    @media (max-width: 576px) {
                        height: 200px;
                    }
                }
            }
        }
    }
}

.blue-bg-main {
    margin: 80px 0;

    .conatiner {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .white-bg-color {
            padding: 40px;
            background-image: linear-gradient(180deg, #658bb9, #3073c6);
            border-radius: 25px;

            @media (max-width: 1024px) {
                padding: 20px;
            }

            .whatismeta5 {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 70px;
                align-items: center;

                @media (max-width: 1024px) {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 25px;
                }

                @media (max-width: 576px) {
                    gap: 10px;
                }

                .meta5-pera {
                    .whatis {
                        h2 {
                            color: #ffffff;
                            font-size: 40px;

                            span {
                                background: linear-gradient(88.9deg, #ffffff 33.43%, #64e584 103.55%);
                                -webkit-background-clip: text;
                                background-clip: text;
                                color: transparent;
                            }

                            @media (max-width: 1024px) {
                                font-size: 34px;
                            }
                        }
                    }

                    p {
                        color: #ffffff;
                        font-size: 18px;
                        line-height: 1.5;
                        margin-top: 15px;
                        font-weight: 600;

                        @media (max-width: 576px) {
                            font-size: 14px;
                        }
                    }

                    .withcomplete {
                        margin-top: 15px;
                        span {
                            color: #ffffff;
                            font-size: 18px;
                            line-height: 1.5;

                            @media (max-width: 576px) {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .meta-trader-img {
                    img {
                        height: 355px;
                        object-fit: contain;

                        @media (max-width: 576px) {
                            height: 370px;
                            width: 100%;
                        }
                    }

                    @media (max-width: 1024px) {
                        text-align: center;
                    }
                }
            }
        }
    }
}
