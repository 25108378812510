.error-section {
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 0;

    .error-video {
        text-align: center;
        width: 100%;

        img {
            width: auto;
        }
    }

    .error-section-inner-main {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        box-sizing: border-box;

        .error-section-main {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            gap: 300px;

            .error-title-main {
                display: flex;
                justify-content: center;
                align-items: center;

                h1 {
                    font-family: "Merriweather", serif;
                    font-size: 80px;
                    font-weight: 500;
                    line-height: 1.1;
                    color: inherit;
                }
            }

            .look-pera-main {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                flex-direction: column;

                h3 {
                    font-size: 30px;
                    margin: 20px 0 10px 0;
                    font-family: "Merriweather", serif;
                    font-weight: 500;
                    line-height: 1.1;
                    color: inherit;
                }

                p {
                    font-size: 14px;
                    font-family: "Merriweather", serif;
                    font-weight: 500;
                    line-height: 1.1;
                    color: inherit;
                    margin: 0 0 10px 0;
                }

                .error-button-go-home {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    a {
                        text-decoration: none;

                        button {
                            width: 130px;
                            height: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #008000;
                            transition: 0.5s ease-in-out;
                            border: 1px solid #008000;

                            span {
                                font-family: "Merriweather", serif;
                                color: #3073c6;
                                border-bottom: 1px solid #fff;
                                transition: 0.5s ease-in-out;
                            }

                            &:hover {
                                background: #fff;
                                transition: 0.5s ease-in-out;

                                span {
                                    font-family: "Merriweather", serif;
                                    transition: 0.5s ease-in-out;
                                    color: #008000;
                                    border-bottom: 1px solid #008000;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .error-section {
        .error-video {
            img {
                width: 100%;
            }
        }

        .error-section-inner-main .error-section-main {
            gap: 100px;

            .error-title-main h1 {
                font-size: 70px;
            }

            .look-pera-main h3 {
                font-size: 28px;
            }

            .look-pera-main p {
                font-size: 13px;
            }

            .error-button-go-home button {
                width: 125px;
                height: 38px;
            }
        }
    }
}

@media (max-width: 992px) {
    .error-section-inner-main .error-section-main {
        gap: 80px;

        .error-title-main h1 {
            font-size: 60px;
        }

        .look-pera-main h3 {
            font-size: 26px;
        }

        .look-pera-main p {
            font-size: 12px;
        }

        .error-button-go-home button {
            width: 120px;
            height: 35px;
        }
    }
}

@media (max-width: 768px) {
    .error-section-inner-main {
        padding: 10px;

        .error-section-main {
            gap: 50px;

            .error-title-main h1 {
                font-size: 50px;
            }

            .look-pera-main h3 {
                font-size: 24px;
            }

            .look-pera-main p {
                font-size: 12px;
            }

            .error-button-go-home button {
                width: 110px;
                height: 32px;
            }
        }
    }
}

@media (max-width: 576px) {
    .error-section-inner-main {
        padding: 10px;

        .error-section-main {
            gap: 40px;

            .error-title-main h1 {
                font-size: 40px;
            }

            .look-pera-main h3 {
                font-size: 22px;
            }

            .look-pera-main p {
                font-size: 11px;
            }

            .error-button-go-home button {
                width: 100px;
                height: 30px;
            }
        }
    }
}

@media (max-width: 480px) {
    .error-section-inner-main {
        padding: 5px;

        .error-section-main {
            gap: 30px;

            .error-title-main h1 {
                font-size: 35px;
            }

            .look-pera-main h3 {
                font-size: 20px;
            }

            .look-pera-main p {
                font-size: 10px;
            }

            .error-button-go-home button {
                width: 90px;
                height: 28px;

                span {
                    font-size: 12px;
                }
            }
        }
    }
}
