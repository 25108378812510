.benefitfrom-section-main {
    width: 100%;
    height: 100%;
    margin: 80px 0;

    .whyselectus-section-container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .whyselectus-section-title-main {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h1 {
                text-align: center;
                background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                color: transparent;
                -webkit-background-clip: text;
                background-clip: text;
                font-size: 18px;
                font-weight: 500;
                line-height: normal;
                margin: 0 0 15px 0;

                @media (max-width: 1024px) {
                    font-size: 14px;
                }

                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }

            h2 {
                font-size: 40px;
                line-height: normal;
                font-weight: 600;
                color: #3073c6;
                margin: 0 0 15px 0;
                max-width: 829px;
                text-align: center;

                @media (max-width: 1024px) {
                    font-size: 30px;
                }

                @media (max-width: 576px) {
                    font-size: 22px;
                    margin-bottom: 10px;
                }
            }

            p {
                font-size: 18px;
                font-weight: 400;
                color: #000000;
                text-align: center;
                max-width: 1114px;
                margin-bottom: 50px;

                @media (max-width: 1024px) {
                    font-size: 16px;
                }

                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }
        }

        .whyselectus-cards-grid-main {
            display: flex;
            justify-content: center;

            @media (max-width: 1024px) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
            }

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .whyselectus-cards-grid-items1 {
                padding: 32px;
                background-image: linear-gradient(142.26deg, #5288cb 1.22%, #3073c6 105.61%);
                border-radius: 24px;
                display: flex;
                justify-content: start;
                align-items: start;
                gap: 7px;
                flex-direction: column;
                position: relative;
                overflow: hidden;
                height: -webkit-fill-available;

                @media (max-width: 576px) {
                    padding: 20px;
                    height: auto;
                }

                span {
                    color: #ffffff;
                    font-size: 16px;
                }

                h3 {
                    font-size: 24px;
                    color: transparent;
                    font-weight: 600;
                    line-height: normal;
                    background: linear-gradient(88.9deg, #ffffff 33.43%, #64e584 103.55%);
                    background-clip: text;

                    @media (max-width: 1024px) {
                        font-size: 22px;
                    }

                    @media (max-width: 576px) {
                        font-size: 18px;
                    }
                }

                p {
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.3;
                    padding-top: 10px;

                    @media (max-width: 1024px) {
                        max-width: 288px;
                    }

                    @media (max-width: 576px) {
                        font-size: 14px;
                    }
                }

                .whyselectus-cards-bottom-line-sc {
                    background: rgb(255, 68, 191) !important;
                }

                .whyselectus-cards-bottom-line-trd {
                    background: rgb(255, 203, 68) !important;
                }

                .whyselectus-cards-bottom-line {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background: rgb(33, 203, 128);
                }
            }
        }
    }
}
