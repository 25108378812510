.amlpolicy {
    padding: 50px 0;
    display: flex;
    justify-content: center;

    .conatiner-aml {
        max-width: 1140px;
        margin: 0 auto;
        padding: 0 20px;

        h1 {
            font-size: 32px;
            color: #3073c6;

            @media (max-width: 576px) {
                font-size: 24px;
            }
        }
    }

    @media (max-width: 576px) {
        padding: 20px 0;
    }
}

.conatiner-aml {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px;
    .main-sub-name {
        margin-top: 30px;

        h2 {
            font-size: 20px;
            color: #3d6bdd;

            @media (max-width: 576px) {
                font-size: 16px;
            }
        }

        @media (max-width: 576px) {
            margin-top: 10px;
        }
    }

    p {
        font-size: 15px;
        font-weight: 300;
        line-height: 1.5;
        margin: 10px 0 0;
        text-align: justify;
        color: #000;

        @media (max-width: 576px) {
            font-size: 12px;
        }
    }

    .means {
        list-style: disc;
        margin-left: 20px;
        margin-top: 10px;
        padding: 0 0 0 10px;

        li {
            color: #000;
            font-size: 15px;
            font-weight: 300;
            line-height: 1.5;
            margin: 10px 0 0;
            text-align: justify;

            &::marker {
                unicode-bidi: isolate;
                font-variant-numeric: tabular-nums;
                text-transform: none;
                text-indent: 0px !important;
                text-align: start !important;
                text-align-last: start !important;
            }

            @media (max-width: 576px) {
                font-size: 12px;
            }
        }
    }

    a {
        color: #000;
        font-weight: 600;
    }
}
