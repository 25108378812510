.becomib-benefits-section {
    background-image: linear-gradient(180deg, #658bb9, #3073c6);
    padding: 48px 0;

    .becomib-benefits-section-container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;
    }

    .becomib-benefits-section-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1 {
            font-size: 37px;
            font-weight: 600;
            color: #fff;
            text-align: center;
            line-height: normal;
            max-width: 750px;
            margin: 0 0 25px 0;
        }

        p {
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            text-align: center;
            line-height: normal;
            max-width: 950px;
        }
    }

    .becomib-benefits-section-content-sc {
        padding: 30px 0 0 0;

        .becomib-benefits-section-content-sc-box {
            padding: 20px;
            border-radius: 6px;
            border: 1px solid #fff;
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 20px;

            .becomib-benefits-section-content-sc-box-content-img {
                border-radius: 50%;
                padding: 25px 25px;
                display: flex;
                justify-content: center;
                align-items: start;
                background-color: #fff;
                position: relative;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }
            }

            p {
                color: #fff;
                font-size: 20px;
                font-weight: 600;
                line-height: normal;
            }
            span {
                color: #fff;
                font-size: 14px;
                margin-top: 5px;
                display: block;
                font-weight: 500;
                line-height: normal;
                max-width: 250px;
            }
        }

        .becomib-benefits-section-content-sc-box-main-one {
            display: flex;
            justify-content: center;
            gap: 30px;

            @media (max-width: 1024px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
            @media (max-width: 576px) {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
            }
        }
        .becomib-benefits-section-content-sc-box-main-two {
            display: flex;
            justify-content: center;
            gap: 30px;
            margin: 30px 0 0 0;

            @media (max-width: 1024px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
            @media (max-width: 576px) {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}
