.platforms {
    .backimg {
        width: 100%;
        height: 100%;
        background: rgb(48, 115, 198);
        background: linear-gradient(
            90deg,
            rgba(48, 115, 198, 0.5214460784313726) 0%,
            rgba(48, 115, 198, 1) 50%,
            rgba(48, 115, 198, 0.5158438375350141) 100%
        );

        .mainplatformdiv {
            width: 100%;
            height: fit-content;
            padding: 50px 0;
            backdrop-filter: blur(5px);
            background: #0000001f;

            .container {
                max-width: 1372px;
                margin: 0 auto;
                padding: 0 20px;
                box-sizing: border-box;
                background-color: transparent;

                .platformsheading {
                    margin-bottom: 18px;
                    text-align: center;

                    h1 {
                        font-size: 18px;
                        background: linear-gradient(88.9deg, #ffffff 33.43%, #64e584 103.55%);
                        color: transparent;
                        -webkit-background-clip: text;
                        background-clip: text;
                        font-weight: 500;

                        @media (max-width: 512px) {
                            font-size: 14px;
                        }
                    }
                }

                .exploreour {
                    text-align: center;
                    margin-bottom: 20px;
                    h2 {
                        font-size: 40px;
                        color: #ffffff;
                        font-weight: 600;

                        @media (max-width: 1024px) {
                            font-size: 30px;
                        }

                        @media (max-width: 576px) {
                            font-size: 25px;
                        }
                    }
                }

                .mt4-mt5-grid {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 20px;
                    justify-content: center;
                    align-items: center;

                    @media (max-width: 1024px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    .platform-grid-items1 {
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        gap: 25px;
                        justify-content: start;

                        @media (max-width: 1024px) {
                            align-items: center;
                        }

                        .platform-title {
                            h4 {
                                font-size: 32px;
                                color: #ffffff;
                                @media (max-width: 1024px) {
                                    font-size: 30px;
                                }

                                @media (max-width: 576px) {
                                    font-size: 25px;
                                }

                                span {
                                    background: linear-gradient(88.9deg, #ffffff 33.43%, #64e584 103.55%);
                                    -webkit-background-clip: text;
                                    background-clip: text;
                                    color: transparent;
                                }
                            }
                        }

                        p {
                            font-size: 18px;
                            color: #ffffff;
                            line-height: 1.5;

                            @media (max-width: 576px) {
                                font-size: 16px;
                                text-align: center;
                            }
                        }

                        .platforms-two-img {
                            .meta4 {
                                img {
                                    width: 100%;
                                }
                            }
                        }

                        .Learn-more {
                            border: none;
                            border-radius: 50px;
                            background-color: transparent;
                            padding: 1px;
                            transition: all 0.2s ease-in-out;

                            &:hover {
                                transform: translateY(-5px);
                                transition: all 0.2s ease-in-out;
                            }
                            button {
                                padding: 15px 25px;
                                border-radius: 50px;
                                width: 100%;
                                border: none;
                                position: relative;
                                background: linear-gradient(88.9deg, #ffffff 33.43%, #64e584 103.55%);
                                -webkit-background-clip: text;
                                background-clip: text;
                                color: transparent;
                                font-size: 20px;
                                font-weight: 600;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 5px;
                                cursor: pointer;

                                &:before {
                                    background: linear-gradient(88.9deg, #fcfcfc 33.43%, #64e584 103.55%);
                                    content: "";
                                    position: absolute;
                                    inset: 0;
                                    border-radius: inherit;
                                    -webkit-mask:
                                        linear-gradient(#fff 0 0) content-box,
                                        linear-gradient(#fff 0 0);
                                    -webkit-mask-composite: xor;
                                    mask-composite: exclude;
                                    padding: 2px;
                                }
                            }

                            svg {
                               path {
                                 stroke: #fff;
                               }
                            }
                        }
                    }

                    .platfromsimages {
                        text-align: center;
                        img {
                            max-height: 500px;

                            @media (max-width: 576px) {
                                width: 100%;
                            }

                            @media (max-width: 1024px) {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }
        }
    }
}
