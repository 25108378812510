.regulations-bg {
    height: 100%;
    width: 100%;
    padding: 55px 0 55px 0;
    position: relative;

    .regulations-backgorund-main {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
            height: 100%;
            width: 100%;
            inset: 0px;
            color: transparent;
            mix-blend-mode: hard-light;
        }
    }

    .container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .regulations-title {
            .regulations-txt-main {
                display: flex;
                justify-content: center;
                align-items: center;
                .regulations-txt {
                    width: fit-content;
                    background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                    color: transparent;
                    -webkit-background-clip: text;
                    background-clip: text;
                    text-align: center;
                    h1 {
                        background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                        color: transparent;
                        -webkit-background-clip: text;
                        background-clip: text;
                        font-size: 52px;
                    }
                }
            }
        }
    }
}

.fscm-main {
    .continer {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .fscm-buttonflex {
            display: flex;
            justify-content: center;
            align-items: center;

            .fscm-button {
                background-color: #1f2532;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                gap: 20px;
                align-items: center;
                padding: 15px 30px;
                width: fit-content;
                cursor: pointer;

                &:hover {
                    background-color: #e0e0e0;

                    .fscm-name {
                        p {
                            color: #000000;
                        }
                    }
                }

                .fscm-icon {
                    height: 32px;
                    width: 32px;
                    img {
                        width: 100%;
                    }
                }

                .fscm-name {
                    p {
                        font-size: 20px;
                        font-weight: 700;
                        color: #3073c6;
                    }
                }
            }
        }

        .fscm-content-main {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            .fscm-contentbox {
                margin-top: 40px;
                background-image: linear-gradient(180deg, #a1c4f0, #3073c6);
                border-radius: 15px;
                border: 1px solid rgb(43, 50, 69);
                padding: 40px;
                width: fit-content;
                display: flex;
                justify-content: start;
                align-items: center;
                flex-direction: column;
                gap: 20px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                .fscm-icon-cont {
                    height: 48px;
                    width: 48px;

                    img {
                        width: 100%;
                    }
                }

                .mexfm {
                    h2 {
                        font-size: 40px;
                    }
                }

                .mexfm-pera {
                    p {
                        font-size: 16px;
                        color: #f1f3f9;
                        line-height: 1.6;
                        max-width: 545px;
                    }
                }

                .viewlicense {
                    margin-top: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media (max-width: 1024px) {
                        width: 100%;
                    }
                    button {
                        background-image: linear-gradient(88.9deg, #ffffff, #64e584 50%, #64e584 75%, #ffffff 120%);
                        padding: 15px;
                        border: none;
                        border-radius: 50px;
                        width: fit-content;
                        font-size: 20px;
                        color: #3073c6;
                        font-weight: 600;
                        background-size: 200% auto !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        transition: 0.4s ease-in-out;
                        cursor: pointer;

                        @media (max-width: 1024px) {
                            font-size: 18px;
                        }

                        @media (max-width: 576px) {
                            font-size: 16px;
                            padding: 12px;
                        }

                        &:hover {
                            transition: 0.4s ease-in-out;
                            background: linear-gradient(
                                88.9deg,
                                #467bff,
                                #75d9d9 50%,
                                #75d9d9 75%,
                                #467bff 120%
                            ) !important;
                            background-size: 200% auto !important;
                            background-position: 100% !important;
                        }

                        svg {
                            margin: 3px 0 0 0;
                            background-color: transparent;
                            path {
                                stroke: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
