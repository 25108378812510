.Headertopline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #3073c6;
    position: relative;
    @media (max-width: 1024px) {
        justify-content: center;
    }

    .Headertopline-contact-and-number {
        display: flex;
        justify-content: start;
        align-items: center;

        .in-number-text {
            text-align: left;
            padding: 0 15px 0 0;

            a {
                text-decoration-line: none;

                span {
                    color: #ffffff;
                    font-size: 15px;
                    line-height: normal;
                    font-weight: 500;
                    letter-spacing: 0.8px;

                    @media (max-width: 576px) {
                        font-size: 12px;
                    }
                }
            }
        }

        .Contact-text {
            padding: 0 0 0 15px;
            border-left: 1px solid #fff;

            a {
                text-decoration-line: none;

                span {
                    color: #ffffff;
                    font-family: "Plus Jakarta Sans", sans-serif;
                    font-size: 14px;
                    line-height: normal;
                    font-weight: 500;
                }
            }
        }
    }

    .World-title-main {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: 1024px) {
            display: none;
        }

        p {
            color: #ffffff;
            font-family: "Plus Jakarta Sans", sans-serif;
            font-size: 16px;
            line-height: normal;
            font-weight: 500;
            letter-spacing: 1px;
        }

        .border-div-header {
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background: linear-gradient(88.9deg, #ffffff 33.43%, #64e584 103.55%);
            animation: Borderrun 2s linear infinite alternate;

            @keyframes Borderrun {
                0% {
                    width: 0%;
                }

                50% {
                    width: 50%;
                }

                100% {
                    width: 100%;
                }
            }
        }
    }

    .Header-top-line-right-buttons {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 10px;

        @media (max-width: 1024px) {
            display: none;
        }

        .Header-top-line-button {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 20px;

            button {
                border: none;
                font-size: 15px;
                font-weight: 500;
                line-height: 19px;
                text-align: center;
                font-family: "Plus Jakarta Sans", sans-serif;
                padding: 6px 15px;
                border-radius: 50px;
                cursor: pointer;
                color: #fff;
                background-color: #ffffff00;
                outline: 1px solid #fff;
            }
        }
    }
}
