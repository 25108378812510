.callback-form-main {
    margin: 83px 0 0 0;

    .name-inputs-main {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        @media (max-width: 800px) {
            grid-template-columns: repeat(1, 1fr);
        }

        input {
            border-radius: 5px;
            border: 1px solid #000000;
            height: 54px;
            width: 100%;
            box-sizing: border-box;
            padding: 0 18px;
            outline: none;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.2;
            color: #000000;
            margin: 19px 0 0 0;
            transition: 0.2s ease-in-out;

            &::placeholder {
                color: #868686;
            }

            &:hover {
                transition: 0.2s ease-in-out;
                border-color: #3073c6;
            }

            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &focus {
                outline: none;
            }
        }

        .telephone-input-main {
            position: relative;
            border-radius: 5px;
            border: 1px solid #000000;
            height: 54px;
            width: 100%;
            box-sizing: border-box;
            padding: 0 18px;
            outline: none;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.2;
            color: #000000;
            margin: 19px 0 0 0;
            display: grid;
            grid-template-columns: 66px 1fr;

            &::placeholder {
                color: #868686;
            }

            &:hover {
                transition: 0.2s ease-in-out;
                border-color: #3073c6;
            }

            .dropdown-list {
                position: absolute;
                box-sizing: border-box;
                top: 52px;
                left: 0;
                z-index: 9;
                width: 266px;
                background-image: linear-gradient(180deg, #cfe4ff, #4f84c5 92.86%);
                border-radius: 2px;
                border: 1px solid #afbccf;
                color: #fff;

                .country-search {
                    margin: 0 2px;
                    position: relative;
                    display: flex;
                    justify-content: end;
                    border-bottom: 1px solid #fff;

                    input {
                        height: 54px;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0 12px 0 50px;
                        outline: none;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 1.2;
                        color: #fff;
                        transition: 0.2s ease-in-out;

                        &::placeholder {
                            color: #fff;
                        }

                        &:hover {
                            transition: 0.2s ease-in-out;
                            border-color: #afbccf;
                        }

                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        &focus {
                            outline: none;
                        }
                    }

                    .searchicon {
                        position: absolute;
                        top: 50%;
                        left: 20px;
                        transform: translateY(-50%);
                        z-index: -1;

                        svg {
                            width: 20px;
                            height: 20px;
                            fill: #fff;
                        }
                    }

                    .clear-search {
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        transform: translateY(-50%);
                        background-color: transparent;
                        border: none;

                        svg {
                            width: 15px;
                            height: 15px;
                            fill: #fff;
                            cursor: pointer;
                        }
                    }
                }

                .country-suggestion-main {
                    padding: 0 0 15px 0;
                    height: 185px;
                    overflow-x: hidden;
                    overflow-y: auto;
                    .form-dropdowncontent {
                        padding: 10px 20px;
                        cursor: pointer;
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        gap: 20px;

                        img {
                            width: 30px;
                            height: 30px;
                        }

                        .region-name-txt {
                            span {
                                color: #3073c6;
                            }
                        }

                        &:hover {
                            background-color: #3073c6;
                        }
                    }
                }
                .nocountryfound {
                    padding: 24px 0;
                    text-align: center;
                }
            }

            .region-select-main {
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 15px;
                cursor: pointer;

                img {
                    width: 20px;
                    height: 20px;
                }

                span {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.2;
                    color: #3073c6;
                }
            }

            input {
                border-radius: 0;
                background-color: transparent;
                background-image: none;
                border: 0;
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                padding: 0;
                outline: none;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.2;
                color: #000000;
                margin: 0;

                &::placeholder {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.2;
                    color: #ffffff;
                }
            }
        }
    }

    .textarea-main {
        width: 100%;
        height: auto;
        margin: 19px 0 0 0;

        label {
            font-size: 12px;
            font-weight: 500;
            display: block;
            margin: 0 0 5px 0;
            color: #868686;
        }

        textarea {
            border-radius: 5px;
            border: 1px solid #000000;
            height: 129px;
            width: 100%;
            box-sizing: border-box;
            padding: 19px 18px;
            outline: none;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.2;
            color: #000000;
            transition: 0.2s ease-in-out;
            resize: none;
            margin: 0;

            &::placeholder {
                color: #868686;
            }

            &:hover {
                transition: 0.2s ease-in-out;
                border-color: #3073c6;
            }

            &focus {
                outline: none;
            }
        }
    }

    .terms-lin-main {
        width: 100%;
        margin: 24px 0 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
            font-size: 15px;
            font-weight: 500;
            line-height: 1.2;
            color: #000000;
            text-align: center;

            a {
                text-decoration-line: none;
                color: rgb(67 126 247 / 1);
            }
        }
    }

    .submit-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 19px 0 0 0;

        a {
            text-decoration-line: none;
        }

        .submit-btn.disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        .submit-btn {
            width: 360px;
            background-image: linear-gradient(88.9deg, #3073c6, #64e584 50%, #64e584 75%, #3073c6 120%);
            padding: 15px 20px;
            border: none;
            border-radius: 50px;
            font-size: 22px;
            color: #ffffff;
            font-weight: 600;
            background-size: 200% auto !important;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            transition: 0.4s ease-in-out;
            cursor: pointer;

            @media (max-width: 1024px) {
                font-size: 18px;
            }

            @media (max-width: 576px) {
                font-size: 16px;
                padding: 12px;
            }

            &:hover {
                transition: 0.4s ease-in-out;
                background-image: linear-gradient(88.9deg, #3073c6, #64e584 50%, #64e584 75%, #3073c6 120%);
                background-size: 200% auto !important;
                background-position: 100% !important;
            }

            &:focus-visible {
                outline: none;
            }

            svg {
                margin: 3px 0 0 0;
                background-color: transparent;

                path {
                    stroke: #fff;
                }
            }
        }
    }
    .dial-code {
        display: none !important;
    }
}
