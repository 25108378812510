.accounts-sections-main {
    width: 100%;
    height: 100%;
    margin-top: 80px;

    .accounts-sections-container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .accounts-sections-title-text-main {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 15px;

            h1 {
                background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                color: transparent;
                -webkit-background-clip: text;
                background-clip: text;
                font-size: 18px;
                font-weight: 500;
                font-family: "Plus Jakarta Sans", sans-serif;
            }

            h2 {
                font-size: 40px;
                line-height: normal;
                font-weight: 600;
                font-family: "Plus Jakarta Sans", sans-serif;
                color: #3073c6;
                margin: 0 0 60px 0;

                @media (max-width: 1024px) {
                    font-size: 30px;
                }
            }
        }

        .accounts-card-grid-main {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            align-items: center;

            @media (max-width: 1024px) {
                display: none;
            }

            .accounts-card-grid-vol1 {
                width: 100%;
                height: 100%;
                padding: 40px;
                overflow: hidden;
                border-radius: 24px;
                background-image: linear-gradient(180deg, #fff, #ddebff);
                position: relative;
                justify-content: flex-start;
                display: flex;
                flex-direction: column;
                gap: 40px;
                box-sizing: border-box;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                .accounts-card-grid-vol1-topline {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 8px;
                    background-color: rgb(173, 68, 255);
                }

                .accounts-card-grid-vol1-topline-sc {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 8px;
                    background-color: rgb(245, 155, 71);
                }

                .planicon-div-sc {
                    background: rgb(245, 155, 71);
                    width: 65px;
                    height: 65px;
                    border-radius: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 32px;
                        height: 32px;
                    }
                }

                .accounts-card-grid-vol1-topline-trd {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 8px;
                    background-color: rgb(85, 149, 252);
                }

                .planicon-div-trd {
                    background: rgb(85, 149, 252);
                    width: 65px;
                    height: 65px;
                    border-radius: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 32px;
                        height: 32px;
                    }
                }

                .planicon-div {
                    background: rgb(173, 68, 255);
                    width: 65px;
                    height: 65px;
                    border-radius: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 32px;
                        height: 32px;
                    }
                }

                .card-data {
                    h1 {
                        color: #161d2f;
                        font-size: 32px;
                        font-weight: 600;
                        line-height: normal;
                        text-align: left;

                        span {
                            color: #3073c6;
                        }
                    }

                    p {
                        margin: 10px 0 0 0;
                        text-align: left;
                        color: #161d2f;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 1.2;
                        // height: 67px;
                    }
                }

                .card-last-details {
                    display: flex;
                    justify-content: start;
                    align-items: start;
                    gap: 10px;
                    flex-direction: column;

                    .minimum-div {
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        gap: 10px;

                        svg {
                            width: 29px;
                            height: 29px;
                        }

                        p {
                            color: #161d2f;
                            font-size: 18px;
                            font-weight: 600;
                            line-height: normal;
                            text-align: left;

                            span {
                                color: #3073c6;
                            }
                        }
                    }
                }
            }
        }
    }
}
