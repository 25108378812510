* {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

header {
    width: 100%;
    box-shadow:
        0 0 #0000,
        0 0 #0000,
        0px 4px 20px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;

    .Headermain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 80px;
        box-sizing: border-box;

        @media (max-width: 1200px) {
            display: none;
        }

        .Headerlogo {
            text-align: left;

            a {
                text-decoration-line: none;
            }
        }

        .header-content {
            display: flex;
            justify-content: end;
            align-items: center;
            gap: 40px;

            .dropdown-container {
                position: relative;

                .anceractive {
                    background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                    color: transparent;
                    -webkit-background-clip: text;
                    background-clip: text;

                    svg {
                        rotate: 0deg;

                        path {
                            fill: #3073c6;
                        }
                    }
                }

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #3073c6;
                    background-color: transparent;
                    border: none;
                    text-decoration-line: none;
                    font-size: 17px;
                    font-weight: 500;
                    height: 51px;
                    gap: 5px;

                    svg {
                        rotate: 180deg;
                        width: 8px;
                        height: 8px;

                        path {
                            fill: #3073c6;
                        }
                    }
                }

                .dropdown-menu1 {
                    z-index: 99;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                    border-radius: 0 0 20px 20px;

                    .dropdown-content-main {
                        border-radius: 0 0 20px 20px;
                        position: relative;
                        box-sizing: border-box;
                        width: 100%;
                        height: 100%;

                        .dropdown-content {
                            border-radius: 0 0 20px 20px;
                            background-color: #ffffff;
                            // position: absolute;
                            // top: 2px;
                            // left: 0;
                            width: 100%;
                            height: calc(100% - 4px);
                            box-sizing: border-box;
                            display: flex;
                            justify-content: start;
                            align-items: center;
                            flex-direction: column;
                            gap: 13px;
                            margin: 2px 0;
                            padding: 15px 35px;

                            a {
                                text-decoration-line: none;
                                min-width: max-content;
                                color: #3073c6;
                                font-size: 14px;
                                font-weight: 500;
                                font-family: "Plus Jakarta Sans", sans-serif;
                                text-align: left;
                                word-break: keep-all;
                                display: block;
                                border: none;
                                height: auto;
                                width: 100%;
                                padding: 12px;
                                background-color: transparent;
                                border-radius: 12px;
                                transition: 0.4s ease-in-out;

                                &:hover {
                                    transition: 0.4s ease-in-out;
                                    background-color: #3073c6;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }

        .headermain-last-buttons {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 10px;
            .dropdown-container {
                position: relative;

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #3073c6;
                    background-color: transparent;
                    border: none;
                    text-decoration-line: none;
                    font-size: 18px;
                    font-weight: 500;
                    width: 85px;
                    height: 51px;
                }
            }

            button {
                border: none;
                font-size: 15px;
                font-weight: 500;
                line-height: 19px;
                text-align: center;
                font-family: "Plus Jakarta Sans", sans-serif;
                padding: 6px 15px;
                border-radius: 50px;
                cursor: pointer;
                transition: 0.4s ease-in-out;
                background-image: linear-gradient(88.9deg, #3073c6, #64e584 50%, #64e584 75%, #3073c6 120%);
                background-size: 200% auto !important;
                color: #fff;
                &:hover {
                    transition: 0.4s ease-in-out;
                    background-image: linear-gradient(88.9deg, #3073c6, #64e584 50%, #64e584 75%, #3073c6 120%);
                    background-size: 200% auto !important;
                    background-position: 100% !important;
                }
            }
        }
    }
}
