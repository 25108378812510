.accounts-details-main {
  margin-top: 50px;
  .accounts-details-container {
    max-width: 1372px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .standard-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin: 0 0 20px 0;

    h1 {
      font-size: 30px;
      font-weight: 700;
      line-height: normal;
      color: #3073c6;
    }

    span {
      width: 120px;
      height: 6px;
      background: #3073c6;
      border-radius: 6px;
    }
  }
  .accounts-details-box-line-one-main {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 24px;
    margin: 80px 0;

    @media (max-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }

    #accounts-details-box-line-1,
    #accounts-details-box-line-2,
    #accounts-details-box-line-3 {
      width: 27%;
      @media (max-width: 1305px) {
        width: 32%;
        box-sizing: border-box;
        // height: 124px;
      }
      @media (max-width: 1250px) {
        width: 31%;
      }

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    // #accounts-details-box-line-2 {
    //   width: 27%;
    //   height: auto;
    //   @media (max-width: 1305px) {
    //     width: 100%;
    //     box-sizing: border-box;
    //     height: 124px;
    //   }
    // }
    // #accounts-details-box-line-3 {
    //   width: 27%;
    //   height: auto;
    //   @media (max-width: 1305px) {
    //     width: 100%;
    //     box-sizing: border-box;
    //     height: 124px;
    //   }
    // }
    #accounts-details-box-line-4,
    #accounts-details-box-line-5,
    #accounts-details-box-line-6,
    #accounts-details-box-line-7 {
      width: 23.1%;
      box-sizing: border-box;
      @media (max-width: 1305px) {
        width: 23%;
        box-sizing: border-box;
        // height: 124px;
      }

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    // #accounts-details-box-line-5 {
    //   width: 22.5%;
    //   height: 131px;
    //   box-sizing: border-box;
    //   @media (max-width: 1305px) {
    //     width: 100%;
    //     box-sizing: border-box;
    //     height: 124px;
    //   }
    // }
    // #accounts-details-box-line-6 {
    //   width: 22.5%;
    //   height: 131px;
    //   box-sizing: border-box;
    //   @media (max-width: 1305px) {
    //     width: 100%;
    //     box-sizing: border-box;
    //     height: 124px;
    //   }
    // }
    // #accounts-details-box-line-7 {
    //   width: 22.5%;
    //   height: 131px;
    //   box-sizing: border-box;
    //   @media (max-width: 1305px) {
    //     width: 100%;
    //     box-sizing: border-box;
    //     height: 124px;
    //   }
    // }

    .accounts-details-box-line-one {
      padding: 32px 32px;
      position: relative;
      border-radius: 24px;

      p {
        position: relative;
        font-size: 24px;
        line-height: normal;
        color: #3073c6;
        font-weight: 800;

        span {
          background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
          color: transparent;
          -webkit-background-clip: text;
          background-clip: text;
        }

        @media (max-width: 576px) {
          font-size: 18px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          99.93deg,
          rgba(136, 149, 172, 0.4),
          rgba(32, 40, 59, 0.4) 10.42%,
          rgba(74, 92, 135, 0.4) 32.81%,
          rgba(32, 40, 59, 0.4)
        );
        padding: 2px;
        border-radius: 24px;
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }

      img {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
