.container1 {
    max-width: 1028px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;

    .choose-where {
        padding: 80px 0;

        .title {
            text-align: center;
            margin-bottom: 70px;

            @media (max-width: 576px) {
                margin-bottom: 60px;
            }

            h2 {
                font-size: 40px;
                color: #3073c6;

                @media (max-width: 1024px) {
                    font-size: 30px;
                }

                @media (max-width: 576px) {
                    font-size: 25px;
                }
            }
        }

        .three-next-img-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            align-items: center;

            @media (max-width: 576px) {
                gap: 10px;
            }

            .whychoose-main {
                position: relative;
                height: 100%;
                background-color: #3073c6;
                display: flex;
                flex-direction: column-reverse;
                overflow: hidden;
                border-radius: 24px;
                padding-top: 10px;
                &:hover {
                    background-image: none;
                    background-color: #fff;
                    transition: 0.4s ease-in-out;

                    .whychoose {
                        h3 {
                            color: #3073c6;
                        }
                    }
                }
                .whychoose {
                    border-radius: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: end;
                    transition: 0.4s ease-in-out;

                    @media (max-width: 576px) {
                        height: 100%;
                        box-sizing: border-box;
                    }

                    &:before {
                        background: linear-gradient(
                            99.93deg,
                            rgba(136, 149, 172, 0.4),
                            rgba(32, 40, 59, 0.4) 10.42%,
                            rgba(74, 92, 135, 0.4) 32.81%,
                            rgba(32, 40, 59, 0.4)
                        );
                        content: "";
                        position: absolute;
                        inset: 0;
                        border-radius: inherit;
                        -webkit-mask:
                            linear-gradient(#fff 0 0) content-box,
                            linear-gradient(#fff 0 0);
                        -webkit-mask-composite: xor;
                        mask-composite: exclude;
                        padding: 2px;
                    }

                    h3 {
                        padding: 0 0 20px 0;
                        text-align: center;
                        z-index: 1;
                        font-weight: 400;
                        color: #ffffff;
                        font-size: 22px;

                        @media (max-width: 1024px) {
                            font-size: 16px;
                        }

                        @media (max-width: 576px) {
                            font-size: 10px;
                        }
                    }

                    .stripedCard {
                        position: absolute;

                        img {
                            width: 100%;
                        }
                    }
                }

                .Whyforexicon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        max-width: 35%;
                        width: 100%;
                    }
                }
            }
        }
    }
}
