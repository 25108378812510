.mobile-header-main {
    display: none;
    padding:0 20px;

    @media (max-width: 1200px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .mobile-Header-logo {
        text-align: left;

        a {
            text-decoration-line: none;

            img {
                width: 150px;
            }
        }
    }

    .mobile-header-menu {
        img {
            cursor: pointer;
            width: 20px;
            height: 20px;
        }
    }
}
