.brokersprogramsections-main {
    width: 100%;
    height: 100%;
    margin: 96px 0;

    .brokersprogramsections-container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;
        box-sizing: border-box;

        .brokersprogramsections-grid-main {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            gap: 50px;

            @media (max-width: 1024px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
            }

            .brokersprogramsections-grid-item1 {
                text-align: left;

                @media (max-width: 1024px) {
                    text-align: center;
                }

                h1 {
                    background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                    color: transparent;
                    -webkit-background-clip: text;
                    background-clip: text;
                    font-size: 40px;
                    font-weight: 800;

                    @media (max-width: 1024px) {
                        font-size: 30px;
                    }

                    @media (max-width: 576px) {
                        font-size: 25px;
                        text-align: center;
                    }
                }

                p {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1.2;
                    margin: 24px 0;
                    color: #000;

                    @media (max-width: 1024px) {
                        font-size: 16px;
                    }

                    @media (max-width: 576px) {
                        margin: 15px 0;
                        text-align: center;
                    }
                }

                span {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1.5;
                    color: #000;

                    @media (max-width: 576px) {
                        font-size: 14px;
                        text-align: center;
                    }
                }

                .become-ib-button-main-alignment {
                    display: flex;
                    justify-content: start;
                    align-items: start;
                    margin: 30px 0 0 0;

                    @media (max-width: 1024px) {
                        justify-content: center;
                    }

                    .become-ib-button {
                        padding: 1px;
                        border-radius: 50px;
                        background-color: transparent;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            transition: all 0.2s ease-in-out;
                            background-color: #fff;
                        }

                        button {
                            display: flex;
                            justify-content: space-between;
                            gap: 10px;
                            align-items: center;
                            border-radius: 50px;
                            padding: 13.5px 26.4px;
                            position: relative;
                            background-color: transparent;
                            border: none;
                            cursor: pointer;

                            span {
                                background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                                color: transparent;
                                -webkit-background-clip: text;
                                background-clip: text;
                                font-weight: 20px;
                                font-weight: 600;
                                line-height: 1.2;
                            }

                            &::before {
                                padding: 2px;
                                content: "";
                                background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                                position: absolute;
                                inset: 0;
                                border-radius: inherit;
                                -webkit-mask:
                                    linear-gradient(#fff 0 0) content-box,
                                    linear-gradient(#fff 0 0);
                                -webkit-mask-composite: xor;
                                mask-composite: exclude;
                            }
                        }
                    }
                }
            }

            .brokersprogramsections-grid-item2 {
                height: 100%;
                width: 100%;
                display: block;
                text-align: center;
                img {
                    width: 100%;
                    @media (max-width: 1024px) {
                        max-width: 450px;
                    }
                }
                @media (max-width: 1024px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                @media (max-width: 576px) {
                    text-align: center;
                }
            }
        }
    }
}
