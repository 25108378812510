.footer-main {
    width: 100%;
    height: 100%;
    padding: 100px 0 40px 0;
    box-sizing: border-box;

    .footer-container {
        max-width: 1664px;
        margin: 0 auto;
        padding: 0 20px;
    }

    .footertopline {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 30px 0;
        border-bottom: 1px solid #919191;

        .footerlogo {
            width: fit-content;
            text-align: left;

            a {
                text-decoration-line: none;

                img {
                    width: auto;

                    @media (max-width: 576px) {
                        width: 130px;
                    }
                }
            }
        }

        .footer-top-line-content {
            display: flex;
            justify-content: end;
            align-items: center;
            gap: 10px;

            a {
                text-decoration-line: none;
                color: #3073c6;
                font-weight: 600;
                font-size: 20px;
                padding: 0 15px 0 0;

                @media (max-width: 576px) {
                    font-size: 10px;
                }
            }
        }
    }

    .footer-content-main-layout {
        border-bottom: 1px solid #919191;
        padding: 24px 0 45px 0;

        @media (max-width: 1024px) {
            text-align: center;
        }

        .footer-content-main {
            display: flex;
            justify-content: space-between;
            align-items: start;
            margin: 0 0 30px 0;
            flex-wrap: nowrap;
            gap: 40px;

            @media (max-width: 1024px) {
                flex-wrap: wrap;
                // display: grid;
                // grid-template-columns: repeat(3, 1fr);
                // margin: 0;
                // gap: 80px;
            }

            @media (max-width: 576px) {
                gap: 20px;
            }

            .footer-content {
                text-align: left;

                p {
                    font-size: 20px;
                    margin: 0 0 15px 0;
                    color: #3073c6;
                    font-weight: 700;
                    line-height: normal;

                    @media (max-width: 576px) {
                        font-size: 14px;
                    }
                }

                .footer-all-links-main {
                    display: flex;
                    justify-content: start;
                    align-items: start;
                    gap: 12px;
                    flex-direction: column;

                    a,
                    span {
                        font-size: 15px;
                        line-height: normal;
                        font-weight: 500;
                        text-decoration-line: none;
                        color: #000000;
                        transition: all 0.3s ease-in-out;
                        max-width: 425px;

                        @media (max-width: 576px) {
                            font-size: 10px;
                        }

                        &:hover {
                            transition: all 0.3s ease-in-out;
                            color: rgb(70 123 255/1);
                        }
                    }

                    .mobilenumber {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        gap: 10px;

                        .callicon {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: 18px;
                            }
                        }
                    }
                }
            }
        }

        .risk-restricted {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: start;
            gap: 50px;
            text-align: left;
            padding: 15px 0;

            @media (max-width: 1024px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 12px;
            }

            .risk-warning {
                p {
                    font-size: 18px;
                    color: #3073c6;
                    font-weight: 600;
                    margin-bottom: 8px;

                    @media (max-width: 576px) {
                        font-size: 14px;
                        margin-bottom: 5px;
                    }
                }
                span {
                    color: #6f6f6f;
                    font-size: 14px;
                    font-weight: 500;

                    @media (max-width: 576px) {
                        font-size: 10px;
                    }
                }
            }
        }
    }

    .footer-bottom-line {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 12px;
        text-align: left;
        padding: 15px 0;

        @media (max-width: 576px) {
            flex-wrap: wrap;
        }

        a {
            text-decoration-line: none;
            color: #000000;
            font-size: 14px;
            font-weight: 500;
            padding: 0 15px 0 0;
            border-right: 1px solid #fff;

            @media (max-width: 1024px) {
                font-size: 10px;
            }

            @media (max-width: 576px) {
                font-size: 8px;
            }
        }

        #risk-side-border-remove {
            padding: 0 !important;
            border-right: none !important;
        }
        @media (max-width: 1024px) {
            justify-content: center;
        }

        @media (max-width: 576px) {
            gap: 10px;
        }
    }

    .footer-copyright-text {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: nowrap;

        @media (max-width: 576px) {
            flex-wrap: wrap;
        }

        p {
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
            color: #3073c6;

            @media (max-width: 1024px) {
                font-size: 12px;
            }

            @media (max-width: 576px) {
                font-size: 10px;
            }
        }

        @media (max-width: 1024px) {
            justify-content: center;
        }
    }
}
