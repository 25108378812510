.whyforex-bg {
    background-image: url("../../assets/images/striped-bg-alt.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;
    transform: rotateY(180deg);

    .container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;
        transform: rotateY(180deg);

        .whyforex-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 1024px) {
                display: block;
                padding: 0 20px;
            }

            @media (max-width: 576px) {
                padding: 0;
            }

            .whyforex-detail {
                h1 {
                    color: #3073c6;
                    font-size: 50px;
                    font-weight: 800;

                    @media (max-width: 1024px) {
                        font-size: 45px;
                    }

                    @media (max-width: 576px) {
                        font-size: 25px;
                    }

                    span {
                        background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                        color: transparent;
                        -webkit-background-clip: text;
                        background-clip: text;
                    }
                }
                p {
                    margin-top: 15px;
                    line-height: 1.5;
                    color: #000000;
                    font-size: 18px;
                    max-width: 790px;

                    @media (max-width: 576px) {
                        font-size: 15px;
                    }
                }
            }

            .whyforex-flex-img {
                img {
                    display: block;
                }
                @media (max-width: 1024px) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}

.features-advantages {
    margin: 80px 0 0 0;

    .container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .whyforex-feat {
            margin-bottom: 15px;
            text-align: center;
            h3 {
                font-size: 18px;
                background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                color: transparent;
                -webkit-background-clip: text;
                background-clip: text;

                @media (max-width: 1024px) {
                    font-size: 16px;
                }

                @media (max-width: 576px) {
                    font-size: 12px;
                }
            }
        }

        h2 {
            text-align: center;
            font-size: 40px;
            color: #3073c6;

            @media (max-width: 1024px) {
                font-size: 30px;
            }

            @media (max-width: 576px) {
                font-size: 24px;
            }
        }

        .four-img-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            margin-top: 80px;

            @media (max-width: 1024px) {
                grid-template-columns: repeat(2, 1fr);
                margin: 40px 0;
            }

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 10px;
            }

            .whychoose {
                position: relative;
                overflow: hidden;
                background-image: linear-gradient(180deg, #a1c4f0, #3073c6);
                border-radius: 24px;
                display: flex;
                align-items: center;
                flex-direction: column-reverse;
                transition: 0.4s ease-in-out;
                padding: 30px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                @media (max-width: 1024px) {
                    padding: 20px;
                }

                // @media (max-width: 576px) {
                //     padding: 10px;
                // }

                &:hover {
                    background-image: none;
                    background-color: #fff;
                    transition: 0.4s ease-in-out;
                    h3 {
                        color: #060b22;
                    }
                }

                &:before {
                    background: linear-gradient(
                        99.93deg,
                        rgba(136, 149, 172, 0.4),
                        rgba(32, 40, 59, 0.4) 10.42%,
                        rgba(74, 92, 135, 0.4) 32.81%,
                        rgba(32, 40, 59, 0.4)
                    );
                    z-index: 1;
                    content: "";
                    position: absolute;
                    inset: 0;
                    border-radius: inherit;
                    -webkit-mask:
                        linear-gradient(#fff 0 0) content-box,
                        linear-gradient(#fff 0 0);
                    -webkit-mask-composite: xor;
                    mask-composite: exclude;
                    padding: 2px;
                }

                h3 {
                    text-align: center;
                    z-index: 1;
                    font-weight: 600;
                    color: #ffffff;
                    font-size: 18px;
                    margin-top: 5px;

                    @media (max-width: 1024px) {
                        font-size: 16px;
                    }

                    @media (max-width: 576px) {
                        font-size: 20px;
                    }
                }

                .stripedCard {
                    position: absolute;
                    bottom: 0;
                    left: 0;

                    img {
                        width: 100%;
                    }
                }
            }

            .Whyforexicon {
                position: relative;
                z-index: 2;
                img {
                    width: 100%;
                }
            }
        }
    }
}

.established-bgcolor {
    // background-image: linear-gradient(142.26deg, #141b35 1.22%, #060b22 105.61%);
    margin: 80px 0;
    padding: 80px 0;

    @media (max-width: 1024px) {
        margin: 0;
    }

    .container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .forex-esta {
            h2 {
                font-size: 40px;
                color: #3073c6;
                font-weight: 700;
                span {
                    background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                    color: transparent;
                    -webkit-background-clip: text;
                    background-clip: text;
                }

                @media (max-width: 1024px) {
                    font-size: 34px;
                }
            }

            @media (max-width: 1024px) {
                padding: 0 20px;
            }

            @media (max-width: 576px) {
                padding: 0;
            }
        }

        .established-grid {
            padding-top: 35px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 60px 32px;

            @media (max-width: 1024px) {
                padding: 30px 20px;
                grid-template-columns: repeat(2, 1fr);
                gap: 30px 16px;
            }

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 20px;
                padding: 15px 0;
            }

            .establish-grid-details {
                display: flex;
                justify-content: start;
                align-items: start;
                gap: 10px;
                flex-direction: column;

                .number {
                    position: relative;
                    width: 44px;
                    height: 44px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    h3 {
                        font-size: 24px;
                        background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                        color: transparent;
                        -webkit-background-clip: text;
                        background-clip: text;

                        @media (max-width: 576px) {
                            font-size: 20px;
                        }
                    }

                    &::before {
                        position: absolute;
                        background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                        content: "";
                        inset: 0;
                        -webkit-mask:
                            linear-gradient(#fff 0 0) content-box,
                            linear-gradient(#fff 0 0);
                        mask-composite: exclude;
                        border-radius: 50%;
                        top: 0;
                        left: 0;
                        padding: 2px;
                    }
                }

                .lucrative {
                    h4 {
                        color: #3073c6;
                        font-size: 20px;
                        font-weight: 600;

                        @media (max-width: 576px) {
                            font-size: 18px;
                        }
                    }
                }

                .wecollaborate {
                    p {
                        color: #000000;
                        font-size: 18px;
                        line-height: 1.4;
                        font-weight: 300;

                        @media (max-width: 576px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

.white-bg {
    background-image: linear-gradient(180deg, #fff, #eaf1ff);
    padding: 65px 0;
    margin-bottom: 80px;

    @media (max-width: 576px) {
        padding: 30px 0;
    }

    .container {
        max-width: 1372px;
        margin: 0 auto;
        padding: 0 20px;

        .dedicated {
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            h2 {
                font-size: 40px;
                color: #000000;
                font-weight: 800;

                @media (max-width: 576px) {
                    font-size: 34px;
                }

                span {
                    background: linear-gradient(88.9deg, #3073c6 33.43%, #64e584 103.55%);
                    color: transparent;
                    -webkit-background-clip: text;
                    background-clip: text;
                }
            }

            p {
                margin-top: 10px;
                color: #000000;
                font-size: 18px;
                max-width: 630px;
                line-height: 1.5;

                @media (max-width: 576px) {
                    font-size: 16px;
                }
            }
        }

        .dedicated-grid {
            padding: 50px 0 0 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;

            @media (max-width: 1024px) {
                grid-template-columns: repeat(2, 1fr);
                gap: 15px;
            }

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
                padding: 30px 0 0 0;
            }

            .linear-darkbox {
                background-image: linear-gradient(180deg, #a1c4f0, #356db2);
                padding: 25px;
                border-radius: 15px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

                .img-sideline-flex {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 24px;

                    .six-img {
                        img {
                        }
                    }

                    .service {
                        p {
                            color: #ffffff;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 1.4;
                        }
                    }
                }
            }
        }
    }
}
