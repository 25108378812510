.riskpagemain {
    .deposit {
        padding: 50px 0;
        display: flex;
        justify-content: center;

        .conatiner-deposit {
            max-width: 1140px;
            margin: 0 auto;
            padding: 0 20px;

            h1 {
                font-size: 32px;
                color: #3073c6;

                @media (max-width: 576px) {
                    font-size: 24px;
                }
            }
        }

        @media (max-width: 576px) {
            padding: 20px 0;
        }
    }

    .conatiner-deposit {
        max-width: 1140px;
        margin: 0 auto;
        padding: 0 20px;

        .main-sub-name-2 {
            h3 {
                font-size: 24px;
                margin-top: 30px;
                color: #3d6bdd;

                @media (max-width: 576px) {
                    font-size: 16px;
                }
            }
        }

        .main-sub-name {
            margin-top: 30px;

            h2 {
                font-size: 20px;
                color: #3d6bdd;

                @media (max-width: 576px) {
                    font-size: 16px;
                }
            }
        }

        p {
            font-size: 15px;
            font-weight: 300;
            line-height: 1.5;
            margin: 10px 0 0;
            text-align: justify;
            color: #000;

            @media (max-width: 576px) {
                font-size: 12px;
            }
        }

        a {
            color: #000;
            font-weight: 600;
        }
    }
}
